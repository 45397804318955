import type { ComponentProps } from 'react'
import { useEffect } from 'react'

import { ApiError } from '@adverity/contracts'
import {
    Button,
    ButtonSecondary,
    Drawer,
    Flex,
    IconWarning,
    ModalDialog,
    Separator,
    SpotIcon,
    Stack,
    Text,
    useMeasure,
} from '@adverity/design-system'
import * as Sentry from '@sentry/react'
import { useRouter } from '@sharedRouter'
import { useQueryErrorResetBoundary } from '@tanstack/react-query'
import type { FallbackProps } from 'react-error-boundary'
import { isError, isPlainObject, isString } from 'remeda'
import { ZodError } from 'zod'
import { fromZodError } from 'zod-validation-error'

import { ErrorPage } from './error-page'

type Props = Omit<FallbackProps, 'error'> & { error: unknown }

const extractError = (error: unknown, fallback = 'Something went wrong'): string => {
    if (isString(error)) {
        return error
    }

    if (error instanceof ZodError) {
        return fromZodError(error).message
    }

    if (isError(error) && error.cause instanceof ZodError) {
        return fromZodError(error.cause).message
    }

    if ((isError(error) || isPlainObject(error)) && isString(error.message) && !!error.message) {
        return error.message
    }

    return fallback
}

const config = {
    large: {
        wrapper: {
            maxWidth: 400,
            spacing: 'medium',
            alignItems: 'center',
        },
        content: {
            spacing: 'small',
            alignItems: 'center',
        },
        text: {
            textAlign: 'center',
        },
        actions: {
            justifyContent: 'center',
            orientation: 'horizontal',
        },
        buttons: {},
    },
    short: {
        wrapper: {
            padding: 'xx-small',
            orientation: 'horizontal',
            justifyContent: 'center',
            alignItems: 'flex-start',
            maxWidth: 700,
        },
        content: {
            spacing: 'small',
            alignItems: 'flex-start',
            orientation: 'horizontal',
            width: '100%',
        },
        text: {
            size: 'xx-small',
            textStyle: 'description',
        },
        actions: {
            orientation: 'horizontal',
            alignItems: 'flex-start',
        },
        buttons: {
            size: 'small',
        },
    },
    narrow: {
        wrapper: {
            padding: 'xx-small',
            orientation: 'vertical',
            justifyContent: 'center',
            alignItems: 'center',
            spacing: 'medium',
            width: '100%',
        },
        content: {
            spacing: 'small',
            width: '100%',
            alignItems: 'center',
        },
        text: {
            textAlign: 'center',
        },
        actions: {
            alignItems: 'flex-start',
            width: '100%',
            orientation: 'vertical',
        },
        buttons: {
            size: 'small',
            width: '100%',
        },
    },
    small: {
        wrapper: {
            padding: 'xx-small',
            orientation: 'horizontal',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        content: {
            spacing: 'small',
            alignItems: 'flex-start',
        },
        text: {},
        actions: {
            orientation: 'horizontal',
            alignItems: 'flex-start',
        },
        buttons: {
            size: 'small',
        },
    },
} as const

const Actions = ({
    resetErrorBoundary,
    size,
    errorId,
}: {
    size: ReturnType<typeof getSize>
    errorId: string | undefined
} & Pick<FallbackProps, 'resetErrorBoundary'>) => {
    const styleConfig = config[size]

    return (
        <Stack spacing="x-small" {...styleConfig.actions}>
            <ButtonSecondary variant="danger" onClick={() => window.location.reload()} {...styleConfig.buttons}>
                Reload Page
            </ButtonSecondary>

            <ButtonSecondary
                variant="danger"
                as="a"
                href={`mailto:support@adverity.com?subject=Support%20%7C%20${errorId}`}
                target="_blank"
                rel="noopener noreferrer"
                {...styleConfig.buttons}
            >
                Contact Support
            </ButtonSecondary>
            <Button autoFocus variant="danger" onClick={resetErrorBoundary} {...styleConfig.buttons}>
                Try again
            </Button>
        </Stack>
    )
}

const Illustration = () => (
    <SpotIcon as="figure" variant="danger">
        <IconWarning />
    </SpotIcon>
)

const getSize = (width: number, height: number) => {
    if (width > 400 && height > 400) {
        return 'large'
    }

    if (width < 400 && height > 500) {
        return 'narrow'
    }

    if (width > 400 && height < 75) {
        return 'short'
    }

    return 'small'
}

export const DefaultFallback = ({ resetErrorBoundary, error }: Props) => {
    const [ref, bounds] = useMeasure()
    const size = getSize(bounds.width, bounds.height)
    const isLarge = size === 'large'
    const styleConfig = config[size]
    const router = useRouter()

    const queryErrorResetBoundary = useQueryErrorResetBoundary()

    useEffect(() => {
        queryErrorResetBoundary.reset()
    }, [queryErrorResetBoundary])

    useEffect(() => {
        if (!import.meta.env.DEV) {
            Sentry.captureException(error, {
                captureContext: {
                    tags: { errorBoundary: true },
                    // TODO: context is missing
                    // contexts: { react: { componentStack: info.componentStack } },
                },
            })
        }
    }, [error])

    const reset = () => {
        void router.invalidate()
        resetErrorBoundary()
    }

    const lastEventId = Sentry.lastEventId()

    if (error instanceof ApiError && error.status === 404) {
        return <ErrorPage errorCode="404" />
    }

    return (
        <Flex
            ref={ref}
            width="calc(100% - 8px)"
            height="calc(100% - 8px)"
            alignItems="center"
            justifyContent="center"
            padding="xx-small"
            as="section"
        >
            <Stack {...styleConfig.wrapper}>
                {!isLarge && <Illustration />}
                <Stack {...styleConfig.content}>
                    {isLarge && <Illustration />}

                    <Text as="p" textStyle="title" {...styleConfig.text}>
                        {extractError(error)}
                    </Text>

                    <Text as="p" {...styleConfig.text}>
                        Try reloading this part of the page using the button below. If the error still occurs, please
                        contact the support team.
                    </Text>
                    {lastEventId && (
                        <Text as="p" textStyle="description" {...styleConfig.text}>
                            Error ID: {lastEventId}
                        </Text>
                    )}
                    {!isLarge && <Actions size={size} resetErrorBoundary={reset} errorId={lastEventId} />}
                </Stack>
                {isLarge && (
                    <>
                        <Separator />
                        <Actions size={size} resetErrorBoundary={reset} errorId={lastEventId} />
                    </>
                )}
            </Stack>
        </Flex>
    )
}

export const ModalDialogFallback = (
    props: Rename<ComponentProps<typeof DefaultFallback>, 'resetErrorBoundary', 'reset'> & { onClose: () => void },
) => {
    const closeDialog = () => {
        props.reset()
        props.onClose()
    }

    return (
        <ModalDialog
            title="Something went wrong"
            onClose={closeDialog}
            applyAction={null}
            cancelAction={{
                label: 'Close',
                onClick: closeDialog,
            }}
        >
            <DefaultFallback error={props.error} resetErrorBoundary={props.reset} />
        </ModalDialog>
    )
}

type Rename<TRecord extends Record<string, unknown>, TKey extends keyof TRecord, TNewName extends string> = Omit<
    TRecord,
    TKey
> & { [K in TNewName]: TRecord[TKey] }

export const DrawerFallback = (
    props: Rename<ComponentProps<typeof DefaultFallback>, 'resetErrorBoundary', 'reset'> &
        Partial<ComponentProps<typeof Drawer>> & { onClose: () => void },
) => {
    const drawerProps = {
        ...props,
        onClose: () => {
            props.reset()
            props.onClose()
        },
        title: props.title ?? 'Something went wrong',
        placement: props.placement ?? 'right',
    }

    return (
        <Drawer {...drawerProps}>
            <DefaultFallback error={props.error} resetErrorBoundary={props.reset} />
        </Drawer>
    )
}
