import type { SkipToken } from '@tanstack/react-query'
import { infiniteQueryOptions, queryOptions, skipToken } from '@tanstack/react-query'
import type { FetchOptions } from 'openapi-fetch'
import type { PathsWithMethod } from 'openapi-typescript-helpers'
import { z } from 'zod'

import { api } from './api'
import type { AllPartialPaths, AllPaths, ApiResponseTypes } from './paths'

class QueryableValue<T extends string> {
    constructor(private value: T) {}

    toJSON = () => String(this.value).split('/').filter(Boolean)

    toString = () => this.value

    valueOf = () => this.value
}

const internalQueryKey = <TPath extends string, TOptions>(path: TPath, options?: TOptions) => {
    const key = new QueryableValue(path)

    if (!options) {
        return [{ path: key.toJSON() }]
    }

    return [{ path: key.toJSON() }, options === skipToken ? null : (options as unknown)]
}

type RemoveNever<T> = {
    [K in keyof T as T[K] extends never ? never : K]: T[K]
}

export type InfiniteQueryPaths = keyof RemoveNever<{
    [Pathname in keyof AllPaths]: AllPaths[Pathname] extends {
        [K in 'get']: { responses: { '200': { content: { 'application/json': { paging: unknown } } } } }
    }
        ? AllPaths[Pathname]['get']['responses']['200']['content']['application/json']['paging']
        : never
}>

export type QueryPaths = Exclude<PathsWithMethod<AllPaths, 'get'>, InfiniteQueryPaths>

const query = <TPath extends QueryPaths, TOptions extends FetchOptions<Pick<AllPaths, QueryPaths>[TPath]['get']>>(
    { path }: { path: TPath },
    ...[options]:
        | ({} extends TOptions ? [Pick<TOptions, 'params' | 'parseAs'>?] : [Pick<TOptions, 'params' | 'parseAs'>]) // eslint-disable-line @typescript-eslint/no-empty-object-type
        | [SkipToken]
) => {
    const key = new QueryableValue(path)

    return queryOptions({
        queryKey: internalQueryKey(path, options),
        queryFn:
            options === skipToken
                ? skipToken
                : async () => {
                      const { data } = await api.get(key.toString(), options as TOptions)

                      return data
                  },
    })
}

const infiniteQuery = <
    TPath extends InfiniteQueryPaths,
    TOptions extends FetchOptions<Pick<AllPaths, InfiniteQueryPaths>[TPath]['get']>,
>(
    { path }: { path: TPath },
    ...[options]:
        | ({} extends TOptions ? [Pick<TOptions, 'params' | 'parseAs'>?] : [Pick<TOptions, 'params' | 'parseAs'>]) // eslint-disable-line @typescript-eslint/no-empty-object-type
        | [SkipToken]
) => {
    const key = new QueryableValue(path)

    return infiniteQueryOptions({
        queryKey: internalQueryKey(path, options),
        queryFn:
            options === skipToken
                ? skipToken
                : async ({ pageParam }): Promise<ApiResponseTypes[TPath]> => {
                      const { data } = await (pageParam
                          ? api.get(key.toString(), {
                                ...options,
                                params: { ...options?.params, query: { token: pageParam } },
                            } as TOptions)
                          : api.get(key.toString(), options as TOptions))

                      return data as never
                  },
        getNextPageParam: (lastPage) => lastPage.paging.next_token,
        getPreviousPageParam: (firstPage) => firstPage.paging.previous_token,
        initialPageParam: '',
    })
}

const queryKeySchema = z.array(z.unknown())
const invalidateSchema = z.array(queryKeySchema).or(z.literal('all')).or(z.literal('none'))
const invalidatesSchema = z
    .object({
        void: invalidateSchema.optional(),
        await: invalidateSchema.optional(),
    })
    .or(z.literal('none'))

export const mutationMetaSchema = z.object({
    invalidates: invalidatesSchema.optional(),
})

type Invalidates = z.infer<typeof invalidatesSchema>
type QueryKeyLike = z.infer<typeof queryKeySchema>

const post = <TPath extends PathsWithMethod<AllPaths, 'post'>>({
    path,
    invalidates,
}: {
    path: TPath
    invalidates?: Invalidates
}) =>
    ({
        mutationFn: <TOptions extends FetchOptions<AllPaths[TPath]['post']>>(
            // eslint-disable-next-line @typescript-eslint/no-empty-object-type
            ...[options]: {} extends TOptions ? [TOptions?] : [TOptions]
        ) => api.post(path, options as TOptions).then(({ data }) => data),
        meta: {
            invalidates,
        },
    }) as const

const patch = <TPath extends PathsWithMethod<AllPaths, 'patch'>>({
    path,
    invalidates,
}: {
    path: TPath
    invalidates?: Invalidates
}) =>
    ({
        mutationFn: <TOptions extends FetchOptions<AllPaths[TPath]['patch']>>(
            // eslint-disable-next-line @typescript-eslint/no-empty-object-type
            ...[options]: {} extends TOptions ? [TOptions?] : [TOptions]
        ) => api.patch(path, options as TOptions).then(({ data }) => data),
        meta: {
            invalidates,
        },
    }) as const

const put = <TPath extends PathsWithMethod<AllPaths, 'put'>>({
    path,
    invalidates,
}: {
    path: TPath
    invalidates?: Invalidates
}) =>
    ({
        mutationFn: <TOptions extends FetchOptions<AllPaths[TPath]['put']>>(
            // eslint-disable-next-line @typescript-eslint/no-empty-object-type
            ...[options]: {} extends TOptions ? [TOptions?] : [TOptions]
        ) => api.put(path, options as TOptions).then(({ data }) => data),
        meta: {
            invalidates,
        },
    }) as const

const del = <TPath extends PathsWithMethod<AllPaths, 'delete'>>({
    path,
    invalidates,
}: {
    path: TPath
    invalidates?: Invalidates
}) =>
    ({
        mutationFn: <TOptions extends FetchOptions<AllPaths[TPath]['delete']>>(
            // eslint-disable-next-line @typescript-eslint/no-empty-object-type
            ...[options]: {} extends TOptions ? [TOptions?] : [TOptions]
        ) => api.delete(path, options as TOptions).then(({ data }) => data),
        meta: {
            invalidates,
        },
    }) as const

const partialQueryKey = <
    TPath extends
        | AllPartialPaths<PathsWithMethod<AllPaths, 'get'>>
        | AllPartialPaths<PathsWithMethod<AllPaths, 'post'>>,
>(
    path: TPath,
): QueryKeyLike => internalQueryKey(path)

const postQueryKey = <
    TPath extends PathsWithMethod<AllPaths, 'post'>,
    TOptions extends FetchOptions<AllPaths[TPath]['post']>,
>(
    { path }: { path: TPath },
    ...[options]: {} extends TOptions ? [Pick<TOptions, 'params' | 'body'>?] : [Pick<TOptions, 'params' | 'body'>] // eslint-disable-line @typescript-eslint/no-empty-object-type
) => internalQueryKey(path, options)

export const apiOptions = {
    query,
    infiniteQuery,
    partialQueryKey,
    postQueryKey,
    mutation: {
        put,
        post,
        patch,
        delete: del,
    },
}
